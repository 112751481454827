import { useEffect, useState } from "react";
import styled from "styled-components";
import arrow from '../../assets/img/up-chevron.png';

const BackToTop = () => {
    const [showArrow, setShowArrow] = useState(false);

    useEffect(() => {
        const foo = (e) => {
            if (e.target.children[0].scrollTop > 100) {
                setShowArrow(true);
            } else {
                setShowArrow(false);
            }
        };

        document.addEventListener('scroll', foo);
        return () => {
            document.removeEventListener('scroll', foo);
        }
    }, []);

    return (
        <Body href="#top" showArrow={showArrow} >
            <img src={arrow} alt={'up'} />
        </Body>
    );
};

export default BackToTop;

const Body = styled.a`
    display: ${(props) => props.showArrow ? 'flex' : 'none'};
    position: fixed;
    bottom: 50px;
    right: 50px;
    background: #a73c55;
    opacity: 0.7;
    z-index: 50;
    border-radius: 50%;
    transition: all 0.3s;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 1;
    }

    img {
        height: 25px;
    }
`;
