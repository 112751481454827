import { useState } from "react";
import styled from "styled-components";
import iconPlus from '../../../assets/img/Springs/icon_plus.svg'
import iconClose from '../../../assets/img/Springs/icon_close.svg'

const PrepareOperation = () => {
    const [openArray, setOpenArray] = useState([]);

    const handleOpen = (id) => {
        if (openArray.includes(id)) {
            setOpenArray((state) => state.filter((item) => item !== id));
        } else {
            setOpenArray((state) => [...state, id]);
        }
    };

    return (
        <Body id='pacient'>
            <h2>Подготовка к операции</h2>

            <div className="opersSprings">
                <Slide opened={openArray.includes('cons')} height={'600px'}>
                    <div className="spring_header" onClick={() => handleOpen('cons')}>
                        <h3>Консультация перед операцией</h3>
                        <img src={openArray.includes('cons') ? iconClose :  iconPlus} alt={'icon'} />
                    </div>

                    <div className="springText">
                        <p>Для записи на операцию необходимо пройти очную или с помощью телекоммуникационных сервисов консультацию с доктором. Первичную консультацию с доктором можно получить очно, либо дистанционно.</p>
                        <p>Стоимость очной консультации – от 3000 руб., дистанционные консультации проводятся без оплаты. Дистанционные консультации проводятся для иногородних пациентов, для определения объема и, соответственно, стоимости операции.</p>
                        <p>Госпитализация в Клиниках производится в день операции, если иное не оговорено с пациентами на консультации.<br/> Приезд иногородних пациентов лучше планировать на раннее утро даты операции (не позднее 09.00), либо накануне. Время операции подтверждается администраторами клиник ЗА ДЕНЬ до даты операции по телефону, в информационном сообщении в WhatsApp или Телеграмм.</p>
                        <h4>Оплата операции производится в день самой операции. Доступны различные способы оплаты: </h4>

                        <ul>
                            <li>Наличными;</li>
                            <li>Картой через терминал;</li>
                            <li>Кредит (сроком до 3 лет);</li>
                            <li>Рассрочка (до 6 месяцев).</li>
                        </ul>
                    </div>
                </Slide>

                <Slide opened={openArray.includes('check')} height={'1376px'}>
                    <div className="spring_header" onClick={() => handleOpen('check')}>
                        <h3>Обследование перед операцией</h3>
                        <img src={openArray.includes('check') ? iconClose :  iconPlus} alt={'icon'} />
                    </div>

                    <div className="springText">
                        <p>Анализы для допуска к операции можно сдать самостоятельно, либо пройти обследование у нас в клинике.</p>
                        <p>Предварительная запись обязательна.<br/>Записаться можно через администраторов клиники</p>
                        <p>Клиника Health and beauty +7 (812) 500-51-03</p>
                        <p>Сестрорецкая клиника +7 (812) 679-07-90</p>
                        <h4>Перечень анализов для операции (местная, общая анестезии):</h4>

                        <ul>
                            <li>АЛТ (действителен 10-14 дней)</li>
                            <li>АСТ (действителен 10-14 дней)</li>
                            <li>Общий билирубин (действителен 10-14 дней)</li>
                            <li>Общий белок (действителен 10-14 дней)</li>
                            <li>Клинический анализ крови (действителен 10-14 дней)</li>
                            <li>Креатинин (действителен 10-14 дней)</li>
                            <li>Коагулограмма + МНО (действителен 10-14 дней)</li>
                            <li>Мочевина (действителен 10-14 дней)</li>
                            <li>Сахар крови (действителен 10-14 дней)</li>
                            <li>СОЭ (действителен 10-14 дней)</li>
                            <li>Общий анализ мочи (действителен 10-14 дней)</li>
                            <li>ВИЧ (Ф-50) (действителен 30 дней)</li>
                            <li>Реакция Вассермана – RW (сифилис) (действителен 30 дней)</li>
                            <li>Гепатит В (HBsAg) (действителен 30 дней)</li>
                            <li>Гепатит С (HCV) (действителен 30 дней)</li>
                            <li>ЭКГ (электрокардиограмма с расшифровкой) (действителен 30 дней)</li>
                            <li>Группа крови и резус-фактор (нет срока)</li>
                            <li>Флюорография (1 год)</li>
                            <li>УЗИ молочных желёз (3 месяца) (для маммопластики)/ УЗИ передней брюшной стенки (на предмет грыжевой деформации) (при липосакции, пластики живота)</li>
                            <li>Консультация терапевта с выдачей заключения об отсутствии противопоказаний для оперативного вмешательства</li>
                        </ul>
                        <p><i>* При наличии хронических заболеваний обязательно необходимо проинформировать хирурга.</i></p>
                        <p><i>* Если вес тела превышает 90 кг, имеются варикозное расширение вен нижних конечностей, необходимо предоставить дуплекс вен нижних конечностей (принести с собой результаты), или можно сделать в клинике, где будет проходить операция.</i></p>
                        <p>Готовые результаты анализов необходимо отправить на почту: dr.shpyrnyi@mail.ru </p>
                        <p>В теме письма ОБЯЗАТЕЛЬНО УКАЗАТЬ: ФИО пациента и дату операции, не позднее 1-2 дней до планируемой даты операции.</p>
                        <p>В день операции необходимо принести результаты анализов в распечатанном виде и с печатями выполнявшей лаборатории.</p>
                    </div>
                </Slide>

                <Slide opened={openArray.includes('price')} height={'651px'}>
                    <div className="spring_header" onClick={() => handleOpen('price')}>
                        <h3>Из чего складывается итоговая стоимость операции</h3>
                        <img src={openArray.includes('price') ? iconClose :  iconPlus} alt={'icon'} />
                    </div>

                    <div className="springText">
                        <ul>
                            <li>Консультация хирурга перед операцией</li>
                            <li>Консультация анестезиолога-реаниматолога (как правило, проводится в день самой операции) </li>
                            <li>Анестезия (наркоз) </li>
                            <li>Сама операция</li>
                            <li>Пребывание в палате с питанием и медицинским мониторингом и сопровождением</li>
                            <li>Послеоперационные осмотры и перевязки, снятие швов</li>
                        </ul>

                        <h4>Дополнительные расходы:</h4>

                        <ul>
                            <li>Анализы и предоперационное обследование</li>
                            <li>Чулки 2 класса компрессии (можно приобрести в клинике, либо самостоятельно (в аптеке, в ортопедическом салоне) </li>
                            <li>Доп. Компрессионное бельё (по желанию) </li>
                            <li>Доп. сутки в клинике -  от 8000 руб. </li>
                            <li>Доп. анализы и обследования (при необходимости) - по прейскуранту клиники </li>
                            <li>Медикаментозный набор для ранней послеоперационной поддержки за пределами клиники (таблетки Супракс 400 мг (либо аналог), Нимесил (либо Найз, Ибупрофен, кетонал), набор для перевязки) </li>
                        </ul>
                    </div>
                </Slide>

                <Slide opened={openArray.includes('before')} height={'1111px'}>
                    <div className="spring_header" onClick={() => handleOpen('before')}>
                        <h3>Перед операцией</h3>
                        <img src={openArray.includes('before') ? iconClose :  iconPlus} alt={'icon'} />
                    </div>

                    <div className="springText">
                        <h4>С собой в клинику в день госпитализации необходимо взять:</h4>

                        <ul>
                            <li>распечатанные результаты анализов</li>
                            <li>паспорт, денежные средства</li>
                            <li>зарядное устройство для мобильного телефона</li>
                            <li>средства личной гигиены (контейнер для линз с раствором, прокладки, расчёска и т.д.)</li>
                            <li>можно взять пижаму, носочки</li>
                        </ul>

                        <p>Форма одежды - свободная, желательно застёгивающаяся спереди.</p>
                        <p>Клиника предоставляет: халат, тапочки, полотенце, гигиенический набор</p>
                        <p>На операцию допускается покрытие ногтей лаком или гель-лаком светлых, либо ярких оттенков. Длина ногтей - короткая. Все остальные покрытия должны быть сняты, тёмные оттенки цветов, также - не допускаются. При операции на веках – все наращённые ресницы должны быт предварительно сняты. На кожу лица в день операции декоративной косметики не наносить.</p>
                        <h4>На время операции ВСЕ украшения должны быть сняты (пирсинг, серьги, кольца и прочее)</h4>
                        <p>За сутки до начала операции необходимо исключить потребление алкоголя и    активные физические нагрузки. Отказаться от никотина за 12 часов до операции, отказаться от употребления алкоголя за 3 дня до наркоза и в течении всего периода пребывания в клинике</p>
                        <p>За 6 часов до начала операции необходимо исключить потребление пищи, за 4 часа - воды. </p>
                        <p>Питание включено в стоимость пребывание в суточном стационаре, однако, если существует непереносимость к каким-то продуктам питания или аллергия, определённая диета, об этом необходимо предупредить администратора клиники заранее (не позднее 1 дня до даты операции) и позаботиться о питании самостоятельно.</p>
                        <h4>Обязательно сообщите анестезиологу перед операцией если:</h4>

                        <ul>
                            <li>Вы страдаете сахарным диабетом</li>
                            <li>У вас повышенное давление</li>
                            <li>Вы страдаете ишемической болезнью сердца или перенесли инфаркт миокарда</li>
                            <li>У вас есть хронические заболевания органов дыхания (например, бронхиальная астма)</li>
                            <li>В ближайшие 2 недели переносили ОРВИ ли грипп</li>
                            <li>Вы постоянно принимаете любые лекарственные препараты (желательно заранее подготовить список этих препаратов)</li>
                            <li>Вам уже делали анестезию и после неё возникли осложнения</li>
                        </ul>
                    </div>
                </Slide>

                <Slide opened={openArray.includes('after')} height={'772px'}>
                    <div className="spring_header" onClick={() => handleOpen('after')}>
                        <h3>После операции</h3>
                        <img src={openArray.includes('after') ? iconClose :  iconPlus} alt={'icon'} />
                    </div>

                    <div className="springText">
                        <p>Пребывание в клинике сопровождающих лиц в ночное время (22.00-07.00) - <b>невозможно</b>.</p>
                        <h4>После анестезии в течении 24 часов опасно:</h4>

                        <ul>
                            <li>Работать, в том числе по дому</li>
                            <li>Управлять транспортным средством</li>
                            <li>Употреблять алкоголь</li>
                            <li>Принимать ответственные решения, подписывать важные документы</li>
                        </ul>

                        <p>Эти виды активности требуют полного восстановления психомоторных функций, что занимает 24−72 часа после операции.<br/>После анестезии пациента должен сопровождать взрослый, дееспособный спутник.</p>
                        <p>Выписка из клиники производится на следующий день после операции при больших операциях после осмотра и перевязки хирурга, как правило, около 12 часов дня, но всегда индивидуально, или же в день операции, если было кратковременное оперативное вмешательство под местной и общей анестезией, с учетом состояния пациента.</p>
                        <p>После выписки из Клиники потребуются послеоперационные осмотры, они назначаются хирургом индивидуально. Записываться на осмотры и перевязки нужно самостоятельно, непосредственно через доктора +7 (911) 192-82-84</p>
                        <p>Противопоказаний к перелёту после операции нет, но, если перелёт более 2-3 часов, доктор рекомендует остаться в городе на 3-5 дней.</p>
                        <p>Для предоставления информации о состоянии здоровья пациента необходимо письменно уведомить администратора клиники, ФИО и контактный телефон лица, которому допустимо передача медицинской информации или информации о состоянии здоровья.</p>
                        <h4>В противном случае, в предоставлении информации будет отказано.</h4>
                    </div>
                </Slide>
            </div>
        </Body>
    );
};

export default PrepareOperation;

const Body = styled.section`
    display: flex;
    flex-direction: column;
    width: 1170px;
    margin: 0 auto;
    padding: 40px 0;

    @media screen and (max-width: 960px) {
        padding: 20px 0;
        width: 100%;
    }

    h2 {
        font-size: 40px;
        line-height: 50px;
        font-weight: 700;
        margin: 0 0 50px;
        text-align: center;

        @media screen and (max-width: 960px) {
            margin: 0 0 20px;
        }
    }

    .opersSprings {
        @media screen and (max-width: 960px) {
            padding: 0 20px;
        }
    }
`;

const Slide = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    border-top: ${(props) => props.opened ? 'none' : '1px solid black'};
    width: 100%;
    height: ${(props) => props.opened ? props.height : '100px'};
    overflow: hidden;
    transition: all 0.3s;
    background-color: ${(props) => props.opened ? '#4b3d55' : '#fff'};
    border-radius: ${(props) => props.opened ? '10px' : '0px'};
    margin-bottom: ${(props) => props.opened ? '10px' : '0px'};;
    color: ${(props) => props.opened ? '#fff' : '#4b3d55'};

    @media screen and (max-width: 960px) {
        height: auto;
    }

    .spring_header {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        padding: 30px 40px;
        margin-right: ${(props) => props.opened ? '5px' : '0'};

        @media screen and (max-width: 960px) {
            padding: 20px;
        }

        h3 {
            font-size: 24px;
            line-height: 40px;
            font-weight: 700;
            margin: 0;

            @media screen and (max-width: 960px) {
                font-size: 20px;
                line-height: 30px;
                margin-right: 20px;
            }
        }

        img {
            @media screen and (max-width: 960px) {
                height: 30px;
                width: 30px;
            }
        }
    }

    .springText {
        /* display: flex; */
        flex-direction: column;
        padding: 0 40px 40px;
        display: ${(props) => props.opened ? 'flex' : 'none'};

        @media screen and (max-width: 960px) {
            padding: 0 20px 20px;
        }

        p {
            margin: 0 0 20px;
            font-size: 16px;
            line-height: 25px;

            @media screen and (max-width: 960px) {
                font-size: 14px;
            }
        }

        h4 {
            font-size: 16px;
            line-height: 25px;
            font-weight: 700;
            margin: 0 0 20px;

            @media screen and (max-width: 960px) {
                font-size: 14px;
            }
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0 0 16px;
        }

        li {
            margin-bottom: 10px;
            padding-left: 50px;
            position: relative;
            font-size: 16px;
            line-height: 25px;

            @media screen and (max-width: 960px) {
                font-size: 14px;
            }
        }

        li::before {
            content: '';
            width: 40px;
            height: 1px;
            position: absolute;
            top: 12px;
            left: 0;
            background: #f6b489;
        }
    }
`;
