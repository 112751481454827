import styled from "styled-components";
import LeftSlider from "./LeftSlider/LeftSlider";
import RightSlider from "./RightSlider/RightSlider";

const ConsultationPlace = () => {

    return (
        <Body>
            <h2 className="place_title">Операции и консультации проводятся</h2>

            <div className="galleries">
                <LeftSlider />

                <RightSlider />
            </div>
        </Body>
    );
};

export default ConsultationPlace;

const Body = styled.section`
    display: flex;
    flex-direction: column;
    padding: 40px 0;

    @media screen and (max-width: 960px) {
        padding: 20px 0;
    }

    .place_title {
        font-size: 40px;
        line-height: 50px;
        font-weight: 700;
        margin: 0 0 50px;
        text-align: center;

        @media screen and (max-width: 960px) {
            margin: 0 0 20px
        }
    }

    .galleries {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        width: 1500px;

        @media screen and (max-width: 1530px) {
            width: 1240px;
        }

        @media screen and (max-width: 960px) {
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
    }
`;
