import Footer from "../Main/Footer";
import Header from "../Main/Header";
import PoliticBody from "./Body";
import BackToTop from "../../components/BackToTop";
import { useEffect } from "react";

const PolitikaPage = () => {
  useEffect(() => {
    document.children[0].scrollTop = 0;
  }, []);
  return (
    <>
      <BackToTop />

      <Header />
      <PoliticBody />
      <Footer />
    </>
  );
};

export default PolitikaPage;
