import { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import IMask from 'imask';
import { postSingForm } from "../../api";
import Button from "../Button";
import crossIcon from '../../assets/img/close.png';

const dictionary = {
    'Лицо': 'face',
    'Тело': 'body',
    'Грудь': 'tits',
    'Липосакция': 'lipo',
    'Интимная пластика': 'pussy',
    'Лазерная и инъекционная косметология': 'laser'
};

const SignModal = ({ setOpenSignModal, typeSignModal }) => {
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');


    const handleClick = (e) => {
        e.stopPropagation();
    };

    const handleSign = (event) => {
        event.preventDefault();

        postSingForm({
            name,
            phoneNumber: number,
            type: dictionary[typeSignModal] || 'common',
        });
    };

    let element;
    let mask;
    useEffect(() => {
        element = document.getElementById('selector');
        const maskOptions = {
            mask: '+{7} (000) 000-00-00'
        };
        mask = IMask(element, maskOptions);
    }, [])
    
    return (
        <Body onClick={() => setOpenSignModal(false)}>
            <Modal onClick={handleClick}>
                <h2>Записаться на консультацию {typeSignModal && '«'+typeSignModal+'»'}</h2>

                <img src={crossIcon} alt={''} onClick={() => setOpenSignModal(false)} />

                <form onSubmit={handleSign}>
                    <input
                        placeholder={"Ваше имя*"}
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />

                    <input
                        id='selector'
                        placeholder={"Телефон*"}
                        required
                        value={mask}
                        onChange={(e) => setNumber(e.target.value)}
                    />

                    <Button text={"записаться"} type={'submit'} />

                    <p>Нажимая на кнопку «Записаться», вы даете согласие на обработку персональных данных и соглашаетесь c {' '}
                        <NavLink to="/politika-konfidentsialnosti">политикой конфиденциальности</NavLink>
                    </p>
                </form>
            </Modal>
        </Body>
    );
};

export default SignModal;

const Body = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000099;
    justify-content: center;
    align-items: center;
    z-index: 100;
`;

const Modal = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 40px;
    margin: 0 20px;
    box-sizing: border-box;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 730px) {
        padding: 20px;
    }

    img {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 25px;
        height: 25px;
        cursor: pointer;

        @media screen and (max-width: 730px) {
            width: 20px;
            height: 20px;
        }
    }

    h2 {
        color: #4b3d55;
        font-size: 42px;
        line-height: 42px;
        font-weight: 700;
        margin: 0 0 20px;
        text-align: left;
    }

    form {
        width: 400px;
        margin: 0 auto;

        @media screen and (max-width: 730px) {
            width: 100%;
        }
    }

    input {
        width: 100%;
        border: none;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
        padding: 0;
        height: 40px;
        border-radius: 0px;

        &:focus {
            outline: none; 
        }
    }

    p {
        font-size: 12px;
        line-height: 20px;
        margin: 20px 0 0;
    }

    a {
        border-bottom: 1px solid rgba(167, 60, 85, 0.5);

        &:hover {
            border-bottom: none;
        }
    }
`;
