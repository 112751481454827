import { useState } from "react";
import styled from "styled-components";
import Button from "../../../components/Button";
import SliderPhoto from "./SliderPhoto";
import SliderReviews from "./SliderReviews";
import ReviewModal from "./ReviewModal";

const Reviews = () => {
    const [openModal, setOpenModal] = useState(false);

    const handelReview = () => {
        setOpenModal(true);
    };

    return (
        <Body id='foto'>
            {openModal && <ReviewModal setOpenModal={setOpenModal} />}

            <h2>Отзывы</h2>

            <div className="reviewBlock">

                <div className="reviewSlider">
                    <h3>Отзывы из соц. сетей</h3>
                    <SliderPhoto />
                </div>

                <div className="reviewSlider2">
                    <h3>Отзывы с порталов</h3>
                    <SliderReviews />

                    <Button
                        text={'оставить отзыв'}
                        onClick={handelReview}
                    />
                </div>
            </div>
        </Body>
    );
};

export default Reviews;

const Body = styled.section`
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    width: 1500px;
    margin: 0 auto;

    @media screen and (max-width: 1540px) {
        width: 1400px;
    }

    @media screen and (max-width: 1460px) {
        width: 1300px;
    }

    @media screen and (max-width: 1350px) {
        width: 1200px;
    }

    @media screen and (max-width: 960px) {
        width: 100%;
        padding: 20px 0;
    }

    h2 {
        font-size: 40px;
        line-height: 50px;
        font-weight: 700;
        margin: 0 0 50px;
        text-align: center;

        @media screen and (max-width: 960px) {
            margin: 0 0 20px;
        }
    }

    .reviewBlock {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 960px) {
            flex-direction: column;
            align-items: center;
        }
    }

    .reviewSlider {
        width: calc(50% - 20px);
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        box-sizing: border-box;
        padding: 60px 50px;
        height: min-content;

        @media screen and (max-width: 960px) {
            margin-bottom: 20px;
            width: 520px;
            padding: 20px;
        }

        @media screen and (max-width: 560px) {
            margin-bottom: 20px;
            width: calc(100% - 20px);
        }

        @media screen and (max-width: 420px) {
            padding-bottom: 65px;
        }

        h3 {
            font-size: 24px;
            line-height: 30px;
            font-weight: 700;
            margin: 0 0 20px;
        }
    }

    .reviewSlider2 {
        width: calc(50% - 20px);
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        box-sizing: border-box;
        padding: 60px 50px;
        height: min-content;

        @media screen and (max-width: 960px) {
            margin-bottom: 20px;
            width: 520px;
            padding: 20px;
        }

        @media screen and (max-width: 560px) {
            margin-bottom: 20px;
            width: calc(100% - 20px);
        }

        @media screen and (max-width: 420px) {
            button {
                margin-left: 10px;
            }
        }

        h3 {
            font-size: 24px;
            line-height: 30px;
            font-weight: 700;
            margin: 0 0 20px;
        }
    }
`;
