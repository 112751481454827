import './styles.css';
import Fancybox from '../../../components/Fancybox';
import doc from '../../../assets/img/AboutMe/about_img.png';
import Button from '../../../components/Button';
import photo1 from '../../../assets/img/AboutMe/photo114.jpg';
import photo2 from '../../../assets/img/AboutMe/photo115.jpg';
import photo3 from '../../../assets/img/AboutMe/photo116.jpg';
import photo4 from '../../../assets/img/AboutMe/photo117.jpg';
import photo5 from '../../../assets/img/AboutMe/photo118.jpg';
import photo6 from '../../../assets/img/AboutMe/photo119.jpg';
import photo7 from '../../../assets/img/AboutMe/photo120.jpg';
import photo8 from '../../../assets/img/AboutMe/photo121.jpg';

const AboutMe = () => {
    return (
        <section className='aboutMeSection' id='about'>
            <div className='aboutMeSection_leftInfo'>
                <h3>Обо мне</h3>
                <p>Специализация:</p>
                <span>Блефаропластика, маммопластика, абдоминопластика, интимная пластика, лазерное омоложение, липосакция, нитевой лифтинг.</span>

                <p>Образование и профессиональная подготовка:</p>
                <ul>
                    <li>2007 г. Военно — Медицинская Академия им. С.М.Кирова,  факультет подготовки врачей для военно- морского флота.</li>
                    <li>2007−2008 гг. Интернатура по специальности хирургия, ВМедА им. С.М.Кирова</li>
                    <li>2009−2011 гг.  Клиническая ординатура по пластической хирургии в СЗГМУ им. И.И. Мечникова на кафедре пластической, эстетической и реконструктивной хирургии</li>
                    <li>2016, 2020 г.г. Курсы профессиональной переподготовки по специальности врач- пластический хирург</li>
                </ul>

                <Fancybox
                    options={{
                        Carousel: {
                            infinite: false,
                        },
                    }}
                >
                    <Button
                        text='ДИПЛОМЫ И СЕРТИФИКАТЫ'
                        data="gallery"
                        href={photo1}
                    />
                        <a data-fancybox="gallery" href={photo2} />
                        <a data-fancybox="gallery" href={photo3} />
                        <a data-fancybox="gallery" href={photo4} />
                        <a data-fancybox="gallery" href={photo5} />
                        <a data-fancybox="gallery" href={photo6} />
                        <a data-fancybox="gallery" href={photo7} />
                        <a data-fancybox="gallery" href={photo8} />
                </Fancybox>
            </div>
            <img src={doc} alt='doc' className='aboutMeSection_doc' />
        </section>
    );
};

export default AboutMe;
