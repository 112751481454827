import { Route, Routes } from "react-router-dom"
import MainPage from "./Pages/Main";
import PolitikaPage from "./Pages/PolitikaPage";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/politika-konfidentsialnosti" element={<PolitikaPage />} />
    </Routes>
  );
};

export default App;
