import { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import IMask from "imask";
import { postSingForm } from "../../../api";
import Button from "../../../components/Button";
import doc from '../../../assets/img/Form/img_form.jpg'

const ConsultationForm = () => {
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        postSingForm({name, number});
    };

    let element;
    let mask;
    useEffect(() => {
        element = document.getElementById('selector13');
        const maskOptions = {
            mask: '+{7} (000) 000-00-00'
        };
        mask = IMask(element, maskOptions);
    }, [])

    return (
        <Body img={doc}>
            <h2>Записаться на консультацию</h2>

            <div className="contentCons">
                <div className="form_wrapper">
                    <form onSubmit={handleSubmit}>
                        <h3>Форма записи</h3>

                        <input
                            placeholder={"Ваше имя*"}
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />

                        <input
                            id='selector13'
                            placeholder={"Телефон*"}
                            required
                            value={mask}
                            onChange={(e) => setNumber(e.target.value)}
                        />

                        <Button text={"записаться"} />

                        <p>Нажимая на кнопку «Записаться», вы даете согласие на обработку персональных данных и соглашаетесь c {' '}
                            <NavLink to="/politika-konfidentsialnosti">политикой конфиденциальности</NavLink>
                        </p>
                    </form>
                </div>

                <div className="doc">
                    <img src={doc} alt='doctor' />
                </div>
            </div>
        </Body>
    );
};

export default ConsultationForm;

const Body = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 40px 0;

    @media screen and (max-width: 960px) {
        padding: 20px 0;
    }

    h2 {
        line-height: 50px;
        font-weight: 700;
        margin: 0 0 50px;
        text-align: center;
        font-size: 40px;

        @media screen and (max-width: 960px) {
            margin: 0 0 20px;
        }
    }

    .contentCons {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 960px) {
            flex-direction: column;
            align-items: center;
        }

        .form_wrapper {
            display: flex;
            padding: 60px;
            border: 6px solid #dcdcdc;
            border-radius: 10px;
            width: 565px;
            box-sizing: border-box;

            @media screen and (max-width: 960px) {
                margin-bottom: 20px;
                padding: 20px;
            }

            @media screen and (max-width: 600px) {
                width: calc(100% - 20px);
            }

            form {
                display: flex;
                flex-direction: column;
                width: 100%;

                h3 {
                    color: #4b3d55;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 30px;
                    margin: 0 0 20px;
                }

                input {
                    width: 100%;
                    border: none;
                    margin-bottom: 20px;
                    border-bottom: 1px solid #ccc;
                    padding: 0;
                    height: 40px;
                    border-radius: 0px;

                    &:focus {
                        outline: none; 
                    }
                }

                p {
                    font-size: 12px;
                    line-height: 20px;
                    margin: 20px 0 0;
                }

                a {
                    border-bottom: 1px solid rgba(167, 60, 85, 0.5);

                    &:hover {
                        border-bottom: none;
                    }
                }
            }
        }
    }

    .doc {
        display: flex;
        justify-content: center;
        width: 565px;
        height: 404px;

        img {
            width: 100%;
            height: 100%;
        }

        @media screen and (max-width: 600px) {
            width: calc(100% - 20px);
            height: auto;
        }
    }
`;
