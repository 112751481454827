import { useEffect, useState } from "react";
import IMask from 'imask';
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { postReviewForm } from "../../../../api";
import Button from "../../../../components/Button";
import crossIcon from '../../../../assets/img/close.png';

const ReviewModal = ({ setOpenModal }) => {
    const [name, setName] = useState('');
    const [phoneNumber, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleClick = (e) => {
        e.stopPropagation();
    };

    const handleSign = (event) => {
        event.preventDefault();

        postReviewForm({
            name,
            phoneNumber,
            email,
            message
        });
    };


    let element;
    let mask;

    useEffect(() => {
        element = document.getElementById('selector12');
        const maskOptions = {
            mask: '+{7} (000) 000-00-00'
        };
        mask = IMask(element, maskOptions);
    }, [])
    

    return (
        <Body onClick={() => setOpenModal(false)}>
            <Modal onClick={handleClick}>
                <h2>Отзывы с порталов</h2>

                <img src={crossIcon} alt={''} onClick={() => setOpenModal(false)} />

                <form onSubmit={handleSign}>
                    <input
                        placeholder={"Ваше имя*"}
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />

                    <input
                        id='selector12'
                        placeholder={"Телефон*"}
                        required
                        value={mask}
                        onChange={(e) => setNumber(e.target.value)}
                    />

                    <input
                        placeholder={"E-mail"}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <textarea
                        placeholder={"Сообщение*"}
                        required
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />

                    <Button text={"отправить"} type={'submit'} />

                    <p>Нажимая на кнопку «Отправить», вы даете согласие на обработку персональных данных и соглашаетесь c {' '}
                        <NavLink to="/politika-konfidentsialnosti">политикой конфиденциальности</NavLink>
                    </p>
                </form>
            </Modal>
        </Body>
    );
};

export default ReviewModal;

const Body = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000099;
    justify-content: center;
    align-items: center;
    z-index: 100;
`;

const Modal = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 40px;
    margin: 0 20px;
    box-sizing: border-box;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 730px) {
        padding: 20px;
    }

    img {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 25px;
        height: 25px;
        cursor: pointer;

        @media screen and (max-width: 730px) {
            width: 20px;
            height: 20px;
        }
    }

    h2 {
        color: #4b3d55;
        font-size: 42px;
        line-height: 42px;
        font-weight: 700;
        margin: 0 0 20px;
        text-align: left;
    }

    form {
        width: 400px;
        margin: 0 auto;

        @media screen and (max-width: 730px) {
            width: 100%;
        }
    }

    input {
        width: 100%;
        border: none;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
        padding: 0;
        height: 40px;
        font-family: 'Gilroy', sans-serif;
        font-size: 14px;
        line-height: 16px;
        color: #333;
        border-radius: 0px;

        &:focus {
            outline: none; 
        }
    }

    textarea {
        width: 100%;
        border: none;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
        padding: 0;
        height: 40px;
        resize: vertical;
        height: 158px;
        font-family: 'Gilroy', sans-serif;
        font-size: 14px;
        line-height: 16px;
        color: #333;

        &:focus {
            outline: none; 
        }
    }

    p {
        font-size: 12px;
        line-height: 20px;
        margin: 20px 0 0;
    }

    a {
        border-bottom: 1px solid rgba(167, 60, 85, 0.5);

        &:hover {
            border-bottom: none;
        }
    }
`;
