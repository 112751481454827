import './styles.css';
import { NavLink } from 'react-router-dom';
import { GeolocationControl, Map, Placemark, RulerControl, TrafficControl, YMaps } from '@pbe/react-yandex-maps';
import point from '../../../assets/img/Footer/icon_address.svg';
import logo from '../../../assets/img/Footer/logo_footer.svg';
import vkLogo from '../../../assets/img/Footer/icon_vk_white.svg';
import telegraLogo from '../../../assets/img/Footer/icon_telegram_white.svg';

const Footer = () => {
    return (
        <footer className='footer' id='contacts'>
            <div  className='footer_yandex'>
                <YMaps>
                    <Map
                        width={'100%'}
                        height={'100%'}
                        defaultState={{
                            center: [59.943058, 30.300410],
                            zoom: 10,
                            controls: ["zoomControl", "fullscreenControl"],
                          }}
                          modules={["control.ZoomControl", "control.FullscreenControl"]}
                    >
                        <Placemark defaultGeometry={[60.106795, 29.964974]}  defaultOptions={{iconColor: 'red'}} />
                        <Placemark defaultGeometry={[59.922549, 30.345977]}  defaultOptions={{iconColor: 'red'}} />
                        <TrafficControl options={{ float: "right" }} />
                        <RulerControl options={{ float: "right" }} />
                        <GeolocationControl options={{ float: "left" }} />
                    </Map>
                </YMaps>
            </div>

            <div className='footer_red'>
                <h3>Клиника "Сестрорецкая"</h3>

                <span className='footer_location'>
                    <img src={point} alt={'location'} />
                    <div className='footer_location_mobile'>
                        г. Сестрорецк, ул. <br className='footer_location_mobile_br' />Пограничников, 2 стр 1
                    </div>
                    </span>

                <span className='footer_lable'>Центр эстетической медицины</span>

                <h3>"Health&Beauty”</h3>

                <span className='footer_location'>
                    <img src={point} alt={'location'} />
                    <div className='footer_location_mobile'>
                        г. Санкт-Петербург, ул.  <br className='footer_location_mobile_br' />Достоевского, 40-44С
                    </div>
                </span>

                <div className='footer_contact'>
                    <a href="tel:+79111928284">+7 (911) 192-82-84</a>

                    <div className='dividerYel'/>

                    <span className='footer_call'>Заказать обратный звонок</span>
                </div>

                <a href="mailto:dr.shpyrnyi@mail.ru" className='footer_email'>dr.shpyrnyi@mail.ru</a>

                <div className='footer_bottom'>
                    <NavLink to='/politika-konfidentsialnosti' className='logo'>
                        <img src={logo} alt={'logo'} />
                    </NavLink>

                    <div className='footer_bottom_center'>
                        <span className='footer_bottom_fio'>Шпырный Антон Константинович</span>

                        <div className='dividerWhite' />

                        <span className='footer_bottom_status'>Пластический хирург</span>
                    </div>

                    <div className='footer_bottom_social'>
                        <a className='footer_bottom_round' href="https://vk.com/">
                            <img src={vkLogo} alt={'vk'} />
                        </a>

                        <a className='footer_bottom_round' href="https://telegram.me/">
                            <img src={telegraLogo} alt={'telegram'} />
                        </a>
                    </div>
                </div>

                <p className='footer_copy'>
                    Имеются противопоказания. Необходима очная консультация специалиста.
                    Представленная на сайте информация не является публичной офертой, определяемой положениями Статьи 437 Гражданского кодекса РФ.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
