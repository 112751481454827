import { useState } from 'react';
import './styles.css';
import Button from '../../../components/Button';
import SignMoadl from '../../../components/SignModal';
import me from '../../../assets/img/TopBlock/me.png';
import pen from '../../../assets/img/TopBlock/icon_pen.svg';

const TopBlock = () => {
    const [isOpenModal, setOpenSignModal] = useState(false);

    const handleClick = () => {
        setOpenSignModal(true);
    };

    return (
        <section className="topSection">
            {isOpenModal && <SignMoadl setOpenSignModal={setOpenSignModal} />}

            <div className="content">
                <div className="content_description">
                    <h3 className="content_description_title">Пластический хирург</h3>
                    <h2 className="content_description_name">Шпырный</h2>
                    <h2 className="content_description_name">Антон Константинович</h2>
                    <h2 className="content_description_name_mobile">Шпырный</h2>
                    <h2 className="content_description_name_mobile">Антон</h2>
                    <h2 className="content_description_name_mobile">Константинович</h2>
                    <p className="content_description_announce">“Люби то, что делаешь, и делай то, что любишь”</p>

                    <Button
                        text='записаться на консультацию'
                        img={pen}
                        onClick={handleClick}
                    />
                </div>

                <img src={me} alt='me' className="mePic" />
            </div>
        </section>
    );
};

export default TopBlock;
