import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import './styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Fancybox from "../../../../components/Fancybox";
import clinicLogo from '../../../../assets/img/g1_1717678151.svg';
import pointIco from '../../../../assets/img/icon_address_white.svg';
import photo1 from '../../../../assets/img/Foto/photo17.webp';
import photo2 from '../../../../assets/img/Foto/photo18.webp';
import photo3 from '../../../../assets/img/Foto/photo19.webp';
import photo4 from '../../../../assets/img/Foto/photo20.webp';
import photo5 from '../../../../assets/img/Foto/photo21.webp';
import photo6 from '../../../../assets/img/Foto/photo22.webp';
import photo7 from '../../../../assets/img/Foto/photo23.webp';
import photo8 from '../../../../assets/img/Foto/photo24.webp';
import photo9 from '../../../../assets/img/Foto/photo25.webp';

const LeftSlider = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => {
    return (
      <div
        style={{
          display: 'flex',
          bottom: '15px',
          left: '40px',
          width: 'calc(100% - 150px)',
          overflow: 'auto',
          scrollbarWidth: 'thin',
          scrollbarColor: 'rgba(167, 60, 85, 0.8) rgba(167, 60, 85, 0.1)',
        }}
      >
        <ul
            style={{
                margin: "0 5px 5px 0",
                padding: '0px',
                display: 'flex',
            }}
        >
            {dots}
        </ul>
      </div>
    )},
    customPaging: (index) => (
      <div
        className={`leftSlider_slide_${index+1}`}
      />
    )
  };

  let moved = false;

  const downListener = (e) => {
    moved = false;
  }

  const moveListener = (e) => {
    moved = true;
  }

  useEffect(() => {
    const elem = document.getElementById('leftSlider_ref');

    const upListener = (e) => {
      if (moved) {
        e.preventDefault();
      }
    };
  
    elem.addEventListener('mousedown', downListener);
    elem.addEventListener('mousemove', moveListener);
    elem.addEventListener('click', upListener);

    return () => {
      elem.removeEventListener('mousedown', downListener);
      elem.removeEventListener('mousemove', moveListener);
      elem.removeEventListener('click', upListener);
    }
  }, []);

  return (
    <div className="leftSlider">
      <div className="leftSlider_content">
          <div className="leftSlider_content_top">
              <h2>Клиника <br className="leftSlider_content_top_br" />в Сестрорецке</h2>
              <img src={clinicLogo} alt={'clinic'} />
          </div>

          <div className="leftSlider_content_bottom">
              <img src={pointIco} alt={'point'} />
              <span>г. Сестрорецк, ул. Пограничников, 2 стр 1</span>
          </div>
      </div>

      <Fancybox
        options={{
          Carousel: {
            infinite: false,
          },
        }}
      >
        <div id='leftSlider_ref'>
          <Slider {...settings}>
            <a data-fancybox="gallery" href={photo1} >
              <img alt="img" src={photo1} className="leftSlider_slide" />
            </a>
            <a data-fancybox="gallery" href={photo2}>
              <img alt="img" src={photo2} className="leftSlider_slide" />
            </a>
            <a data-fancybox="gallery" href={photo3}>
              <img alt="img" src={photo3} className="leftSlider_slide" />
            </a>
            <a data-fancybox="gallery" href={photo4}>
              <img alt="img" src={photo4} className="leftSlider_slide" />
            </a>
            <a data-fancybox="gallery" href={photo5}>
              <img alt="img" src={photo5} className="leftSlider_slide" />
            </a>
            <a data-fancybox="gallery" href={photo6}>
              <img alt="img" src={photo6} className="leftSlider_slide" />
            </a>
            <a data-fancybox="gallery" href={photo7}>
              <img alt="img" src={photo7} className="leftSlider_slide" />
            </a>
            <a data-fancybox="gallery" href={photo8}>
              <img alt="img" src={photo8} className="leftSlider_slide" />
            </a>
            <a data-fancybox="gallery" href={photo9}>
              <img alt="img" src={photo9} className="leftSlider_slide" />
            </a>
          </Slider>
        </div>
      </Fancybox>
    </div>
  );
};

export default LeftSlider;
