import styled from "styled-components";

const Button = ({
    text = '',
    img,
    onClick,
    data,
    href
}) => {

    return (
        <Body onClick={onClick} data-fancybox={data} href={href}>
            {img && <img src={img} alt='img' />}
            {text}
        </Body>
    );
};

export default Button;

const Body = styled.button`
    display: flex;
    text-transform: uppercase;
    background: linear-gradient(90deg, #d15976, #a73c55);
    color: #fff;
    padding: 13px 40px;
    font-size: 12px;
    font-weight: 700;
    border: none;
    width: max-content;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-family: 'Gilroy', sans-serif;
    cursor: pointer;
    z-index: 10;

    &:hover {
        background: linear-gradient(90deg, #a73c55, #d15976);
    }

    img {
        margin-right: 8px;
    }
`;
