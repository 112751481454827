import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'http://localhost:5000',
});

const sendSignForm = (data) => {
    return axiosInstance.post('sign-form', data);
};

const sendReviewForm = (data) => {
    return axiosInstance.post('review-form', data);
};

export const postSingForm = async (data) => {
    try {
        const res = await sendSignForm(data);

        alert('Успешно');
    } catch (e) {
        alert('Не получилось');
    }
};

export const postReviewForm = async (data) => {
    try {
        const res = await sendReviewForm(data);

        alert('Успешно');
    } catch (e) {
        alert('Не получилось');
    }
};
