import React, { useState } from "react";
import Slider from "react-slick";
import './styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TwoSectionSlider = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setSlideIndex(next)
  };

  return (
    <div className="sliderSection">
      <div className="sliderSection_index">
        {slideIndex + 1}/3
      </div>

      <Slider {...settings}>
        <div className="sliderSection_slide-1">
          <div className="sliderSection_content">
            <span className="sliderSection_content_sale">Ацкия</span>

            <div className="sliderSection_content_slash" />

            <p className="sliderSection_content_title">Лазерное омоложение</p>

            <p className="sliderSection_content_description">-50% до конца месяца</p>
          </div>
        </div>

        <div className="sliderSection_slide-2">
          <div className="sliderSection_content">
            <span className="sliderSection_content_sale">Ацкия</span>

            <div className="sliderSection_content_slash" />

            <p className="sliderSection_content_title">Лазерное омоложение</p>

            <p className="sliderSection_content_description">-50% до конца месяца</p>
          </div>
        </div>

        <div className="sliderSection_slide-3">
          <div className="sliderSection_content">
            <span className="sliderSection_content_sale">Ацкия</span>

            <div className="sliderSection_content_slash" />

            <p className="sliderSection_content_title">Лазерное омоложение</p>

            <p className="sliderSection_content_description">-50% до конца месяца</p>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default TwoSectionSlider;
