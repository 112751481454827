import './styles.css';
import styled from 'styled-components';
import { useState } from 'react';
import OperationModal from './modal';
import SignMoadl from '../../../components/SignModal';
import facePic from '../../../assets/img/Operations/c1_1717668058.webp';
import bodyPic from '../../../assets/img/Operations/c2_1717668068.webp';
import titsPic from '../../../assets/img/Operations/c3_1717668078.webp';
import assPic from '../../../assets/img/Operations/c4_1717668089.webp';
import pussyPic from '../../../assets/img/Operations/c5_1717668101.webp';
import laserPic from '../../../assets/img/Operations/c6_1717668112.webp';

const Operations = () => {
    const array = [
        {
            title: 'Лицо',
            img: facePic
        }, {
            title: 'Тело',
            img: bodyPic
        }, {
            title: 'Грудь',
            img: titsPic
        }, {
            title: 'Липосакция',
            img: assPic
        }, {
            title: 'Интимная пластика',
            img: pussyPic
        }, {
            title: 'Лазерная и инъекционная косметология',
            img: laserPic
        }
    ];

    const [modalType, setModalType] = useState(null);
    const [typeSignModal, setTypeSignModal] = useState(null);

    const handleClick = (card) => {
        setModalType(card.title);
    };

    return (
        <Body id='operations'>
            {modalType && <OperationModal modalType={modalType} setModalType={setModalType} setTypeSignModal={setTypeSignModal} />}
            {typeSignModal && <SignMoadl typeSignModal={typeSignModal} setOpenSignModal={setTypeSignModal} />}

            <h2>Операции</h2>

            <div className='cards'>
                {array.map((card, index) => {

                    return (
                        <Card onClick={() => handleClick(card)} key={index + '_card'} img={card.img}>
                            <h4>{card.title}</h4>
                        </Card>
                    );
                })}
            </div>
        </Body>
    );
};

export default Operations;

const Body = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1172px;
    margin: 0 auto;
    padding: 60px 0 40px;

    @media screen and (max-width: 960px) {
        padding: 20px 0;
    }

    h2 {
        font-size: 40px;
        line-height: 50px;
        font-weight: 700;
        text-align: center;
        margin: 0 0 50px;

        @media screen and (max-width: 960px) {
            margin: 0 0 20px;
        }
    }

    .cards {
        display: flex;
        flex-wrap: wrap;

        @media screen and (max-width: 960px) {
            flex-direction: column;
            align-items: center;
        }
    }
`;

const Card = styled.div`
    background-image: url(${(props) => props.img});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    width: 364px;
    height: 391px;
    margin: 0 40px 40px 0;
    border-radius: 10px;
    cursor: pointer;

    @media screen and (max-width: 960px) {
        margin: 0 0 20px 0;
    }

    @media screen and (max-width: 400px) {
        width: calc(100% - 20px);
    }
    &:nth-child(3n) {
        margin-right: 0;
    }

    h4 {
        font-size: 28px;
        line-height: 30px;
        font-weight: 500;
        color: #fff;
        margin: 0;
        padding: 40px 30px;
    }

    &:last-child {
        h4 {
            font-size: 24px;
            width: 140px;
        }
    }
`;