import { useState } from "react";
import styled from "styled-components";
import { HashLink as Link } from 'react-router-hash-link';
import { NavLink } from "react-router-dom";
import logo from '../../../assets/img/header/logo.svg';
import whatsappLogo from '../../../assets/img/header/icon_whatsapp.svg';
import vkLogo from '../../../assets/img/header/icon_vk.svg';
import telegraLogo from '../../../assets/img/header/icon_telegram.svg';
import iconBurger from '../../../assets/img/header/icon_opener.svg';

const Header = () => {
    const [openBurger, setOpenBurger] = useState(false);

    const getBurger = () => {
        if (window.location.pathname === '/') {
            return (
                <nav>
                    <Link to="#about">Обо мне</Link>
                    <Link to="#operations">Операции</Link>
                    <Link to="#pacient">Для Пациентов</Link>
                    <a href="https://vk.com/dr.asclepion" target="_blank">Фото работ</a>
                    <Link to="#contacts">Контакты</Link>
                </nav>
            );
        } else {
            return (
                <nav>
                    <NavLink to="/">Главная</NavLink>
                </nav>
            );
        }
    };

    return (
        <Body>
            <Burger openBurger={openBurger}>
                <img src={iconBurger} alt='burger' onClick={() => setOpenBurger((state) => !state)} />

                {getBurger()}
            </Burger>

            <div className="header_top">
                <a className="left_block" href="/">
                    <img src={logo} alt='logo' className="header_logo" />
                    <div className="header_info">
                        <span className="header_info_name">Шпырный Антон Константинович</span>

                        <div className="slash" />

                        <span className="header_info_title">Пластический хирург</span>
                    </div>
                </a>

                <div className="rigth_block">
                    <div className="contact">
                        <a href="tel:+79111928284" className="contact_phone">+7 (911) 192-82-84</a>

                        <div className="slash" />

                        <div className="contact_whatsapp">
                            <img src={whatsappLogo} alt='logo' className="contact_whatsapp_logo" />
                            <a className="contact_whatsapp_title" href="https://wa.me/79111928284">Задать вопрос в whatsapp</a>
                        </div>
                    </div>
                    <div className="social">
                        <a href="https://vk.com/"><img src={vkLogo} alt='vklogo' /></a>
                        <a href="https://telegram.me/"><img src={telegraLogo} alt='telegalogo' /></a>
                    </div>
                </div>
            </div>

            <div className="divider" />

            <nav className="navigarion">
                <Link to="/#about">Обо мне</Link>
                <Link to="/#operations">Операции</Link>
                <Link to="/#pacient">Для Пациентов</Link>
                <a href="https://vk.com/dr.asclepion" target="_blank">Фото работ</a>
                <Link to="/#contacts">Контакты</Link>
            </nav>
        </Body>
    );
};

export default Header;

const Body = styled.header`
    display: flex;
    flex-direction: column;
    align-items: center;

    .header_top {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1170px;
        padding: 20px 0 30px;

        @media screen and (max-width: 960px) {
            flex-direction: column-reverse;
            align-items: center;
            padding: 15px 0;
            max-width: none;
        }

        .slash {
            display: flex;
            height: 1px;
            width: 70px;
            background-color: #333;
            margin: 8px 0;

            @media screen and (max-width: 960px) {
                display: none;
            }
        }

        .left_block {
            display: flex;
            align-items: center;

            .header_logo {

            }

            .header_info {
                display: flex;
                flex-direction: column;
                margin-left: 40px;
                width: 150px;

                @media screen and (max-width: 960px) {
                    display: none;
                }

                .header_info_name {
                    line-height: 22px;
                    font-weight: 700;
                    color: #333;
                }

                .header_info_title {
                    color: #6d6d6d;
                }
            }
        }
        
        .rigth_block {
            display: flex;

            .contact {
                display: flex;
                flex-direction: column;

                &_phone {
                    text-decoration: none;
                    color: #333;
                    font-size: 26px;
                    line-height: 30px;
                    font-weight: 700;

                    @media screen and (max-width: 960px) {
                        margin-bottom: 10px;
                    }
                }

                &_phone:hover {
                    color: #a73c55;
                }

                &_whatsapp {
                    display: flex;
                    align-items: center;
                    @media screen and (max-width: 960px) {
                        display: none;
                    }
                    &_logo {
                        
                    }
                    
                    &_title {
                        color: #a73c55;
                        text-decoration: none;
                        margin-left: 6px;
                    }
                }

                &_whatsapp:hover {
                    opacity: 0.9;
                }
            }
        }

        .social {
            display: flex;
            margin: 15px 0 0 40px;
            @media screen and (max-width: 960px) {
                display: none;
            }
            a {
                display: flex;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 1px solid #a73c55;
                align-items: center;
                justify-content: center;
                margin-left: 10px;
            } 

            a:hover {
                border: 1px solid #333;
            } 
        }
    }

    .divider {
        display: flex;
        width: 100%;
        height: 1px;
        background-color: #d3d3d3;
    }

    .navigarion {
        display: flex;
        width: 100%;
        max-width: 1170px;
        justify-content: space-between;
        padding: 16px 0;

        @media screen and (max-width: 960px) {
            display: none;
        }

        a {
            text-transform: uppercase;
            font-weight: 600;
            line-height: 17px;
        }

        a:hover {
            color: #a73c55;
        }
    }
`;

const Burger = styled.div`
    display: none;
    flex-direction: column;
    position: absolute;
    top: 18px;
    left: 18px;

    @media screen and (max-width: 960px) {
        display: flex;
    }

    img {
        height: 20px;
        width: 20px;
        cursor: pointer;
    }

    nav {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 28px;
        left: -20px;
        height: ${(props) => props.openBurger ? '185px' : '0px'};
        transition: all 0.3s;
        overflow: hidden;
        z-index: 20;

        a {
            padding: 10px 20px; 
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: .1em;
            text-transform: uppercase;
            text-align: center;
            background-color: #fff;
            white-space: nowrap;
        }
    }
`;
