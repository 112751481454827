import Header from "./Header";
import TopBlock from "./TopBlock";
import TwoCards from "./TwoCards";
import AboutMe from "./AboutMe";
import Operations from "./Operations";
import ConsultationForm from "./ConsultationForm";
import ConsultationPlace from "./ConsultationPlace";
import PrepareOperation from "./PrepareOperation";
import Reviews from "./Reviews";
import SingConsultation from "./SingConsultation";
import Footer from "./Footer";
import BackToTop from "../../components/BackToTop";

const MainPage = () => {
  return (
    <>
      <BackToTop />

      <Header />
      <TopBlock />
      <TwoCards />
      <AboutMe />
      <Operations />
      <ConsultationForm />
      <ConsultationPlace />
      <PrepareOperation />
      <Reviews />
      <SingConsultation />
      <Footer />
    </>
  );
};

export default MainPage;
