import './styles.css';
import TwoSectionSlider from './Slider';

const TwoCards = () => {
    return (
        <section className='twoSection'>
            <TwoSectionSlider />

            <div className='rightSection'>
                <div className='rightSection_wrapper'>
                    <div className='left_info'>
                        <span className='right_info_credit'>Кредитная рассрочка</span>
                        <p className='right_info_procent'>0<span>%</span></p>
                    </div>

                    <div className='right_info'>Осуществи мечту сейчас! </div>
                </div>
            </div>
        </section>
    );
};

export default TwoCards;
