import styled from "styled-components";
import Button from "../../../components/Button";
import facePic from '../../../assets/img/Operations/c1_1717668058.webp';
import bodyPic from '../../../assets/img/Operations/c2_1717668068.webp';
import titsPic from '../../../assets/img/Operations/c3_1717668078.webp';
import assPic from '../../../assets/img/Operations/c4_1717668089.webp';
import pussyPic from '../../../assets/img/Operations/c5_1717668101.webp';
import laserPic from '../../../assets/img/Operations/c6_1717668112.webp';
import pencel from '../../../assets/img/pencl_white.svg';
import crossIcon from '../../../assets/img/close.png';

const OperationModal = ({ modalType, setModalType, setTypeSignModal }) => {
    const handleClick = (e) => {
        e.stopPropagation();
    };

    const handleSign = () => {
        setModalType(null);
        setTypeSignModal(modalType);
    };

    const getBody = () => {
        if (modalType === 'Лицо') {
            return (
                <div className="operationModal_wrapper">
                    <img className="operationModal_img" src={facePic} alt={'face'}  />
                    <div className="operationModal_content">
                        <h2>Лицо</h2>
                        <img src={crossIcon} alt={''} onClick={() => setModalType(false)} className="operationModal_content_exit" />

                        <ul>
                            <li>Пластика верхних век: 85000 руб.</li>
                            <li>Пластика нижних век: 95000</li>
                            <li>Трансконъюнктивальная блефаропластика (пластика нижних век бесшовным методом): 95000 руб.</li>
                            <li>Круговая блефаропластика (пластика верхних и нижних век): 160 000 руб.</li>
                            <li>Подтяжка 2/3 лица и шеи (ритидэктомия): от 200000 руб.</li>
                            <li>Подтяжка 2/3 лица и шеи (SMAS) (с липосакцией шеи): от 280000 руб.</li>
                            <li>Подтяжка шеи с платизмопластикой: от 115000 руб.</li>
                            <li>Подтяжка шеи: от 92000 руб.</li>
                            <li>Удаление комков Биша: 55000 руб.</li>
                            <li>Хейлопластика (пластика губ редукционная), Верхняя и нижняя губа: от 65000 руб.</li>
                            <li>Коррекция мочки уха (1 ухо): от 23000 руб.</li>
                        </ul>
                        <p>Все цены указаны за саму операцию, дополнительно на консультации определяется тип анестезиологического пособия, время нахождения в стационаре, предоперационное обследование. Итоговая стоимость определяется на очной консультации.</p>
                        <Button img={pencel} text={"записаться на консультацию"} onClick={() => handleSign(modalType)} />
                    </div>
                </div>
            )
        }
        if (modalType === 'Тело') {
            return (
                <div className="operationModal_wrapper">
                    <img className="operationModal_img" src={bodyPic} alt={'body'}  />
                    <div className="operationModal_content">
                        <h2>Тело</h2>
                        <img src={crossIcon} alt={''} onClick={() => setModalType(false)} className="operationModal_content_exit" />

                        <ul>
                            <li>Миниабдоминопластика: от 130000 руб.</li>
                            <li>Миниабдоминопластика с липосакцией 2 зон: от 160000 руб.</li>
                            <li>Абдоминопластика (без переноса пупка): от 220000 руб.</li>
                            <li>Абдоминопластика (с переносом пупка) и липосакцией: от 320000 руб.</li>
                            <li>Подтяжка плеч (брахиопластика): от 115000 руб.</li>
                        </ul>
                        <p>Все цены указаны за саму операцию, дополнительно на консультации определяется тип анестезиологического пособия, время нахождения в стационаре, предоперационное обследование. Итоговая стоимость определяется на очной консультации.</p>
                        <Button img={pencel} text={"записаться на консультацию"} onClick={() => handleSign(modalType)} />
                    </div>
                </div>
            )
        }
        if (modalType === 'Грудь') {
            return (
                <div className="operationModal_wrapper">
                    <img className="operationModal_img" src={titsPic} alt={'tits'}  />
                    <div className="operationModal_content">
                        <h2>Грудь</h2>
                        <img src={crossIcon} alt={''} onClick={() => setModalType(false)} className="operationModal_content_exit" />

                        <ul>
                            <li>Увеличение груди (без стоимости имплантов): от 150000 руб.</li>
                            <li>Мастопексия (подтяжка) без аугментации 2 груди: от 190000 руб.</li>
                            <li>Мастопексия (подтяжка груди с вертикальным рубцом): от 280000 руб.</li>
                            <li>Мастопексия с одномоментной аугментацией груди (импланты приобретаются отдельно): 250000 руб.</li>
                            <li>Редукционная маммопластика (уменьшение и подтяжка груди): от 350000 руб.</li>
                            <li>Пластика сосково-ареолярного комплекса (2 молочные железы): от 50000 руб.</li>
                        </ul>
                        <Button img={pencel} text={"записаться на консультацию"} onClick={() => handleSign(modalType)} />
                    </div>
                </div>
            )
        }
        if (modalType === 'Липосакция') {
            return (
                <div className="operationModal_wrapper">
                    <img className="operationModal_img" src={assPic} alt={'ass'}  />
                    <div className="operationModal_content">
                        <h2>Липосакция</h2>
                        <img src={crossIcon} alt={''} onClick={() => setModalType(false)} className="operationModal_content_exit" />

                        <ul>
                            <li>Липосакция подбородка: от 35000 руб.</li>
                            <li>Туннелирование + липосакция подбородочной области: 46000 руб.</li>
                            <li>Липосакция 1 зоны на туловище и конечностях: 35000 руб.</li>
                            <li>Липосакция 2 зон на туловище и конечностях: 65000 руб.</li>
                            <li>Липосакция дополнительной зоны: 20000 руб.</li>
                        </ul>
                        <Button img={pencel} text={"записаться на консультацию"} onClick={() => handleSign(modalType)} />
                    </div>
                </div>
            )
        }
        if (modalType === 'Интимная пластика') {
            return (
                <div className="operationModal_wrapper">
                    <img className="operationModal_img" src={pussyPic} alt={'pussy'}  />
                    <div className="operationModal_content">
                        <h2>Интимная пластика</h2>
                        <img src={crossIcon} alt={''} onClick={() => setModalType(false)} className="operationModal_content_exit" />

                        <ul>
                            <li>Пластика малых половых губ: от 50000 руб.</li>
                            <li>Лабиопластика больших половых губ: от 70000 руб.</li>
                        </ul>
                        <Button img={pencel} text={"записаться на консультацию"} onClick={() => handleSign(modalType)} />
                    </div>
                </div>
            )
        }
        if (modalType === 'Лазерная и инъекционная косметология') {
            return (
                <div className="operationModal_wrapper">
                    <img className="operationModal_img" src={laserPic} alt={'laser'}  />
                    <div className="operationModal_content">
                        <h2>Лазерная и инъекционная косметология</h2>
                        <img src={crossIcon} alt={''} onClick={() => setModalType(false)} className="operationModal_content_exit" />

                        <Button img={pencel} text={"записаться на консультацию"} onClick={() => handleSign(modalType)} />
                    </div>
                </div>
            )
        }
    };

    return (
        <Body onClick={() => setModalType(false)}>
            <Modal onClick={handleClick}>
                {getBody()}
            </Modal>
        </Body>
    );
};

export default OperationModal;

const Body = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000099;
    justify-content: center;
    align-items: center;
    z-index: 100;
`;

const Modal = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 40px;
    position: relative;
    width: 1170px;
    border-radius: 10px;
    margin: 0 20px;

    @media screen and (max-width: 960px) {
        max-width: calc(100% - 40px);
        max-height: calc(100% - 40px);
        height: max-content;
        width: max-content;
        padding: 0;
    }

    .operationModal_wrapper {
        display: flex;
        position: relative;

        @media screen and (max-width: 960px) {
            flex-direction: column;
            padding: 20px;
            overflow: auto;
            scrollbar-width: thin;
        }

    }

    .operationModal_img {
        border-radius: 10px;
        width: 364px;
        height: 391px; 

        @media screen and (max-width: 500px) {
            width: calc(90%);
            height: auto;
        }
    }

    .operationModal_content {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-left: 40px;

        @media screen and (max-width: 960px) {
            margin-left: 0;
        }

        h2 {
            font-size: 28px;
            line-height: 40px;
            font-weight: 700;
            margin: 0 0 20px;
        }

        .operationModal_content_exit {
            position: absolute;
            right: 0px;
            top: 0px;
            width: 25px;
            height: 25px;
            cursor: pointer;

            @media screen and (max-width: 960px) {
                right: 10px;
                top: 20px;  
            }
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

        }

        li {
            margin-bottom: 10px;
            padding-left: 50px;
            position: relative;
            font-size: 16px;
            line-height: 25px;

            @media screen and (max-width: 960px) {
                font-size: 14px;
            }

            
            @media screen and (max-width: 960px) {
                padding-left: 40px;
            }
        }

        li::before {
            content: '';
            width: 40px;
            height: 1px;
            position: absolute;
            top: 12px;
            left: 0;
            background: linear-gradient(90deg, #d15976, #a73c55);

            @media screen and (max-width: 520px) {
                width: 30px;
            }
        }

        p {
            font-style: italic;
            font-size: 16px;
            line-height: 25px;
            margin: 14px 0 20px;
        }
    }
`;
