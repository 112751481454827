import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles.css';
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Fancybox from '../../../../components/Fancybox';
import img1 from "../../../../assets/img/Reviews/photo56.webp";
import img1_b from "../../../../assets/img/Reviews/Big/photo56.jpg";
import img2 from "../../../../assets/img/Reviews/photo57.webp";
import img2_b from "../../../../assets/img/Reviews/Big/photo57.jpg";
import img3 from "../../../../assets/img/Reviews/photo58.webp";
import img3_b from "../../../../assets/img/Reviews/Big/photo58.jpg";
import img4 from "../../../../assets/img/Reviews/photo59.webp";
import img4_b from "../../../../assets/img/Reviews/Big/photo59.jpg";
import img5 from "../../../../assets/img/Reviews/photo60.webp";
import img5_b from "../../../../assets/img/Reviews/Big/photo60.jpg";
import img6 from "../../../../assets/img/Reviews/photo61.webp";
import img6_b from "../../../../assets/img/Reviews/Big/photo61.jpg";
import img7 from "../../../../assets/img/Reviews/photo62.webp";
import img7_b from "../../../../assets/img/Reviews/Big/photo62.jpg";
import img8 from "../../../../assets/img/Reviews/photo63.webp";
import img8_b from "../../../../assets/img/Reviews/Big/photo63.jpg";
import img9 from "../../../../assets/img/Reviews/photo64.webp";
import img9_b from "../../../../assets/img/Reviews/Big/photo64.jpg";
import img10 from "../../../../assets/img/Reviews/photo65.webp";
import img10_b from "../../../../assets/img/Reviews/Big/photo65.jpg";
import img11 from "../../../../assets/img/Reviews/photo66.webp";
import img11_b from "../../../../assets/img/Reviews/Big/photo66.jpg";
import img12 from "../../../../assets/img/Reviews/photo67.webp";
import img12_b from "../../../../assets/img/Reviews/Big/photo67.jpg";
import img13 from "../../../../assets/img/Reviews/photo68.webp";
import img13_b from "../../../../assets/img/Reviews/Big/photo68.jpg";
import img14 from "../../../../assets/img/Reviews/photo69.webp";
import img14_b from "../../../../assets/img/Reviews/Big/photo69.jpg";
import img15 from "../../../../assets/img/Reviews/photo70.webp";
import img15_b from "../../../../assets/img/Reviews/Big/photo70.jpg";
import img16 from "../../../../assets/img/Reviews/photo71.webp";
import img16_b from "../../../../assets/img/Reviews/Big/photo71.jpg";
import img17 from "../../../../assets/img/Reviews/photo72.webp";
import img17_b from "../../../../assets/img/Reviews/Big/photo72.jpg";
import img18 from "../../../../assets/img/Reviews/photo73.webp";
import img18_b from "../../../../assets/img/Reviews/Big/photo73.jpg";
import img19 from "../../../../assets/img/Reviews/photo74.webp";
import img19_b from "../../../../assets/img/Reviews/Big/photo74.jpg";
import img20 from "../../../../assets/img/Reviews/photo75.webp";
import img20_b from "../../../../assets/img/Reviews/Big/photo75.jpg";
import img21 from "../../../../assets/img/Reviews/photo76.webp";
import img21_b from "../../../../assets/img/Reviews/Big/photo76.jpg";
import img22 from "../../../../assets/img/Reviews/photo77.webp";
import img22_b from "../../../../assets/img/Reviews/Big/photo77.jpg";
import img23 from "../../../../assets/img/Reviews/photo78.webp";
import img23_b from "../../../../assets/img/Reviews/Big/photo78.jpg";
import img24 from "../../../../assets/img/Reviews/photo79.webp";
import img24_b from "../../../../assets/img/Reviews/Big/photo79.jpg";

const SliderPhoto = () => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  let moved = false;

  const downListener = (e) => {
    moved = false;
  }

  const moveListener = (e) => {
    moved = true;
  }

  useEffect(() => {
    const elem = document.getElementById('rowsSlide_grid1');

    const upListener = (e) => {
      if (moved) {
        e.preventDefault();
      }
    };
  
    elem.addEventListener('mousedown', downListener);
    elem.addEventListener('mousemove', moveListener);
    elem.addEventListener('click', upListener);

    return () => {
      elem.removeEventListener('mousedown', downListener);
      elem.removeEventListener('mousemove', moveListener);
      elem.removeEventListener('click', upListener);
    }
  }, []);


  return (
    <div className="rowsSlider_container">
      <Fancybox
        options={{
          Carousel: {
            infinite: false,
          },
        }}
      >
        <div id='rowsSlide_grid1'>
          <Slider {...settings}>
            <div className="rowsSlide_grid">
              <a data-fancybox="gallery" href={img1_b} className="rowsSlide">
                <img alt="img" src={img1} />
              </a>
              <a data-fancybox="gallery" href={img2_b} className="rowsSlide">
                <img alt="img" src={img2} />
              </a>
              <a data-fancybox="gallery" href={img3_b} className="rowsSlide">
                <img alt="img" src={img3} />
              </a>
              <a data-fancybox="gallery" href={img4_b} className="rowsSlide">
                <img alt="img" src={img4} />
              </a>
            </div>

            <div className="rowsSlide_grid">
              <a data-fancybox="gallery" href={img5_b} className="rowsSlide">
                <img alt="img" src={img5} />
              </a>
              <a data-fancybox="gallery" href={img6_b} className="rowsSlide">
                <img alt="img" src={img6} />
              </a>
              <a data-fancybox="gallery" href={img7_b} className="rowsSlide">
                <img alt="img" src={img7} />
              </a>
              <a data-fancybox="gallery" href={img8_b} className="rowsSlide">
                <img alt="img" src={img8} />
              </a>
            </div>

            <div className="rowsSlide_grid">
              <a data-fancybox="gallery" href={img9_b} className="rowsSlide">
                <img alt="img" src={img9} />
              </a>
              <a data-fancybox="gallery" href={img10_b} className="rowsSlide">
                <img alt="img" src={img10} />
              </a>
              <a data-fancybox="gallery" href={img11_b} className="rowsSlide">
                <img alt="img" src={img11} />
              </a>
              <a data-fancybox="gallery" href={img12_b} className="rowsSlide">
                <img alt="img" src={img12} />
              </a>
            </div>

            <div className="rowsSlide_grid">
              <a data-fancybox="gallery" href={img13_b} className="rowsSlide">
                <img alt="img" src={img13} />
              </a>
              <a data-fancybox="gallery" href={img14_b} className="rowsSlide">
                <img alt="img" src={img14} />
              </a>
              <a data-fancybox="gallery" href={img15_b} className="rowsSlide">
                <img alt="img" src={img15} />
              </a>
              <a data-fancybox="gallery" href={img16_b} className="rowsSlide">
                <img alt="img" src={img16} />
              </a>
            </div>

            <div className="rowsSlide_grid">
              <a data-fancybox="gallery" href={img17_b} className="rowsSlide">
                <img alt="img" src={img17} />
              </a>
              <a data-fancybox="gallery" href={img18_b} className="rowsSlide">
                <img alt="img" src={img18} />
              </a>
              <a data-fancybox="gallery" href={img19_b} className="rowsSlide">
                <img alt="img" src={img19} />
              </a>
              <a data-fancybox="gallery" href={img20_b} className="rowsSlide">
                <img alt="img" src={img20} />
              </a>
            </div>

            <div className="rowsSlide_grid">
              <a data-fancybox="gallery" href={img21_b} className="rowsSlide">
                <img alt="img" src={img21} />
              </a>
              <a data-fancybox="gallery" href={img22_b} className="rowsSlide">
                <img alt="img" src={img22} />
              </a>
              <a data-fancybox="gallery" href={img23_b} className="rowsSlide">
                <img alt="img" src={img23} />
              </a>
              <a data-fancybox="gallery" href={img24_b} className="rowsSlide">
                <img alt="img" src={img24} />
              </a>
            </div>
          </Slider>
        </div>
      </Fancybox>
    </div>
  );
};

export default SliderPhoto;
