import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import IMask from 'imask';
import { postSingForm } from "../../../api";
import woman from '../../../assets/img/SignConsultation/bg_fb1.png'
import man from '../../../assets/img/SignConsultation/bg_fb2.png'
import Button from "../../../components/Button";

const SingConsultation = () => {
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        postSingForm({name, number});
    };

    let element;
    let mask;
    useEffect(() => {
        element = document.getElementById('selector1');
        const maskOptions = {
            mask: '+{7} (000) 000-00-00'
        };
        mask = IMask(element, maskOptions);
    }, [])
    
    return (
        <Body>
            <img src={woman} alt='woman' />

            <form onSubmit={handleSubmit}>
                <h3>Записаться на консультацию</h3>

                <input
                    placeholder={"Ваше имя*"}
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <input
                    id='selector1'
                    placeholder={"Телефон*"}
                    required
                    value={mask}
                    onChange={(e) => setNumber(e.target.value)}
                />
            
                <Button text={"записаться"} />

                <p>Нажимая на кнопку «Записаться», вы даете согласие на обработку персональных данных и соглашаетесь c {' '}
                    <NavLink to="/politika-konfidentsialnosti">политикой конфиденциальности</NavLink>
                </p>
            </form>

            <img src={man} alt='man' />
        </Body>
    );
};

export default SingConsultation;

const Body = styled.section`
    display: flex;
    background:#4b3d55;
    color: #fff;
    border-radius: 10px;
    width: 1170px;
    margin: 40px auto;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        width: calc(100% - 20px);
        margin: 0 auto 40px;
    }

    img {
        height: fit-content;
        margin-top: auto;
        border-radius: 10px;

        @media screen and (max-width: 960px) {
            width: 20%;
        }

        @media screen and (max-width: 680px) {
            display: none;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        width: 450px;  
        padding: 90px 60px;

        @media screen and (max-width: 960px) {
            padding: 20px;
            margin: 0 auto;
        }

        h3 {
            color: #f6b489;
            margin: 0 0 30px;
            text-align: left;
            font-size: 40px;
            line-height: 50px;
            font-weight: 700;
        }

        input {
            width: 100%;
            border: none;
            margin-bottom: 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            padding: 0;
            height: 40px;
            color: #fff;
            background-color: transparent;
            border-radius: 0px;

            &:focus {
                outline: none; 
            }
        }

        p {
            font-size: 12px;
            line-height: 20px;
            margin: 20px 0 0;
        }

        a {
            border-bottom: 1px solid rgba(167, 60, 85, 0.5);
            color: #fff;

            &:hover {
                border-bottom: none;
            }
        }
    }
`;
