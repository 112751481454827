import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles.css';
import { reviews } from '../../../../assets/files/reviews';

const reviewInSlide = 3;

const SliderReviews = () => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slideCount = Math.ceil(reviews.length / reviewInSlide);
  const slideArray = Array(slideCount);

  for (let i = 1; i <= slideCount; i++) {
    slideArray[i-1] = i;
  }

  return (
    <div className="reviewSlide_container">
      <Slider {...settings}>
        <div className="reviewSlide_1">
          <div className="reviewSlide_review">
            <div className="reviewSlide_review_top">
              <h4>{reviews[0].date}</h4>
              <p>{reviews[0].name}</p>
            </div>

            <p dangerouslySetInnerHTML={{ __html: `${reviews[0].text}`}}></p>
          </div>

          <div className="reviewSlide_review">
            <div className="reviewSlide_review_top">
              <h4>{reviews[1].date}</h4>
              <p>{reviews[1].name}</p>
            </div>

            <p dangerouslySetInnerHTML={{ __html: `${reviews[1].text}`}}></p>
          </div>

          <div className="reviewSlide_review">
            <div className="reviewSlide_review_top">
              <h4>{reviews[2].date}</h4>
              <p>{reviews[2].name}</p>
            </div>

            <p dangerouslySetInnerHTML={{ __html: `${reviews[2].text}`}}></p>
          </div>
        </div>

        <div className="reviewSlide_2">
          <div className="reviewSlide_review">
            <div className="reviewSlide_review_top">
              <h4>{reviews[3].date}</h4>
              <p>{reviews[3].name}</p>
            </div>

            <p dangerouslySetInnerHTML={{ __html: `${reviews[3].text}`}}></p>
          </div>

          <div className="reviewSlide_review">
            <div className="reviewSlide_review_top">
              <h4>{reviews[4].date}</h4>
              <p>{reviews[4].name}</p>
            </div>

            <p dangerouslySetInnerHTML={{ __html: `${reviews[4].text}`}}></p>
          </div>

          <div className="reviewSlide_review">
            <div className="reviewSlide_review_top">
              <h4>{reviews[5].date}</h4>
              <p>{reviews[5].name}</p>
            </div>

            <p dangerouslySetInnerHTML={{ __html: `${reviews[5].text}`}}></p>
          </div>
        </div>

        <div className="reviewSlide_3">
          <div className="reviewSlide_review">
            <div className="reviewSlide_review_top">
              <h4>{reviews[6].date}</h4>
              <p>{reviews[6].name}</p>
            </div>

            <p dangerouslySetInnerHTML={{ __html: `${reviews[6].text}`}}></p>
          </div>

          <div className="reviewSlide_review">
            <div className="reviewSlide_review_top">
              <h4>{reviews[7].date}</h4>
              <p>{reviews[7].name}</p>
            </div>

            <p dangerouslySetInnerHTML={{ __html: `${reviews[7].text}`}}></p>
          </div>

          <div className="reviewSlide_review">
            <div className="reviewSlide_review_top">
              <h4>{reviews[8].date}</h4>
              <p>{reviews[8].name}</p>
            </div>

            <p dangerouslySetInnerHTML={{ __html: `${reviews[8].text}`}}></p>
          </div>
        </div>

        <div className="reviewSlide_3">
          <div className="reviewSlide_review">
            <div className="reviewSlide_review_top">
              <h4>{reviews[9].date}</h4>
              <p>{reviews[9].name}</p>
            </div>

            <p dangerouslySetInnerHTML={{ __html: `${reviews[9].text}`}}></p>
          </div>

          <div className="reviewSlide_review">
            <div className="reviewSlide_review_top">
              <h4>{reviews[10].date}</h4>
              <p>{reviews[10].name}</p>
            </div>

            <p dangerouslySetInnerHTML={{ __html: `${reviews[10].text}`}}></p>
          </div>

          <div className="reviewSlide_review">
            <div className="reviewSlide_review_top">
              <h4>{reviews[11].date}</h4>
              <p>{reviews[11].name}</p>
            </div>

            <p dangerouslySetInnerHTML={{ __html: `${reviews[11].text}`}}></p>
          </div>
        </div>

        <div className="reviewSlide_3">
          <div className="reviewSlide_review">
            <div className="reviewSlide_review_top">
              <h4>{reviews[12].date}</h4>
              <p>{reviews[12].name}</p>
            </div>

            <p dangerouslySetInnerHTML={{ __html: `${reviews[12].text}`}}></p>
          </div>

          <div className="reviewSlide_review">
            <div className="reviewSlide_review_top">
              <h4>{reviews[13].date}</h4>
              <p>{reviews[13].name}</p>
            </div>

            <p dangerouslySetInnerHTML={{ __html: `${reviews[13].text}`}}></p>
          </div>

          <div className="reviewSlide_review">
            <div className="reviewSlide_review_top">
              <h4>{reviews[14].date}</h4>
              <p>{reviews[14].name}</p>
            </div>

            <p dangerouslySetInnerHTML={{ __html: `${reviews[14].text}`}}></p>
          </div>
        </div>

        <div className="reviewSlide_3">
          <div className="reviewSlide_review">
            <div className="reviewSlide_review_top">
              <h4>{reviews[15].date}</h4>
              <p>{reviews[15].name}</p>
            </div>

            <p dangerouslySetInnerHTML={{ __html: `${reviews[15].text}`}}></p>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default SliderReviews;
